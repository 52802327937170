.sl-grid {
  display: grid;
  grid-template-columns: 25% 20% 20% 20% 8%;
  grid-gap: 5px;
  width: 100%;
}
.action-bars i {
  color: blue;
  background: #fff;
  cursor: pointer;
}
.action-bars i:hover {
  background-color: blue;
  color: #fff;
}
.action-cart a {
  color: rgb(255, 68, 68);
  background: #fff;
}
.action-cart a:hover {
  color: #fff;
  background: #ff0000;
}
/* css edit from coordinator  */
.cd-container {
  position: relative;
}
.cd-absolute {
  position: fixed;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border: 1px solid rgba(255, 0, 0, 0.414);
  width: 70%;
}
.float-end i {
  background: #ff0000;
  padding: 8px;
  color: #fff;
  border-radius: 2px;
}

.cd-absolute h2 {
  text-align: center;
  color: rgb(89, 89, 89);
  padding: 10px;
  border-bottom: 1px solid rgb(168, 168, 168);
}
.sp-list-grid {
  display: grid;
  grid-template-columns: 15% 85%;
  width: 80%;
  margin: 10px 0;
}
.cd-main-container {
  margin-bottom: 10px;
  position: relative;
}
.pagiControl{
  position: absolute;
  bottom: -60px;
  right: 18px;
}
.coordinator-table-list-container > tr > td {
  font-size: 14px;
  font-weight: 500;
}
.coordinator-table-list-container > tr:hover {
  background-color: rgb(245, 245, 245);
}

.sponsor-action-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(205, 205, 205);
  padding: 6px;
  margin: 0px 4px;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  z-index: 999;
}
.sponsor-action-box:nth-child(1) {
  color: rgb(102, 102, 102);
}
.sponsor-action-box:nth-child(2) {
  color: rgb(128, 97, 255);
}

.sponsor-action-box:nth-child(3) {
  /* background-color: rgb(249, 93, 124); */
  color: white;
}

.sponsor-action-box:hover {
  opacity: 0.7;
}

/* NO DATA FOUND MESSAGE */
.sponsordetail-no-data-found-message {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sponsordetail-no-data-found-message > h1 {
  width: 100%;
  font-size: 30px;
  padding: 10px;
  color: rgb(52, 63, 83);
  word-break: break-all;
}
.sponsordetail-no-data-found-message > h1 > span {
  color: red;
}

/* SPONSOR FILTER SECTON____-------- */
.sponsor-filter-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: white;
  padding: 1px;
  border-radius: 3px;
}
/* SPONSOR METADATA LIST _ */
.sponsor-detail-metadata-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px;
}
.sponsor-detail-metadata-list > div {
  align-items: center;
  display: flex;
  gap: 5px;
}
.sponsor-detail-metadata-list > div:nth-child(1) > h1 {
  font-size: 15px;
  color: rgb(188, 63, 51);
}

.sponsor-detail-metadata-list > div:nth-child(1) > h1 > span {
  color: rgb(128, 128, 128);
  margin-right: 10px;
  font-weight: 400;
}
.sponsor-detail-metadata-list > div:nth-child(2) > a {
  background-color: rgb(88, 48, 44);
  padding: 5px 10px;
  text-decoration: none;
  color: white;
  border-radius: 3px;
}
.sponsor-detail-metadata-list > div:nth-child(1) {
  position: relative;
}

@media screen and (max-width: 768px) {
  .cd-absolute {
    padding: 10px;
  }
  .float-end i {
    padding: 6px;
  }
  .sp-list-grid {
    width: 100%;
  }
}
@media screen and (max-width: 425px) {
  .cd-absolute {
    width: 95%;
  }
  .sp-list-grid {
    grid-template-columns: none;
  }
}
/* ======orderlist==== */
.ol-more {
  transition: all 0.5s ease-in-out;
}
.ol-more:hover {
  background: rgb(218, 218, 218);
}
