.sp-btn {
  text-align: end;
}
.sp-btn button {
  background: rgba(121, 62, 44, 1);
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  padding: 3px 20px;
  text-align: center;
  outline: none;
  border-radius: 3px;
  border: 1px solid rgba(121, 62, 44, 1);
  transition: all 0.3s ease-in-out;
}
.sp-btn button:hover {
  border: 1px solid rgba(121, 62, 44, 1);
  background-color: #fff;
  color: rgba(121, 62, 44, 1);
}
.errorBorder {
  border: 1px solid #c30202 !important;
  border-radius: 4px;
}
.errorMsg {
  color: #c30202;
  text-transform: capitalize;
}
.sp-contact {
  background: #e9e9e982;
  padding: 5px;
  border-radius: 2px;
}
