.sign-main-container {
  height: 100vh;
  width: 100vw;
  background-color: rgb(232, 229, 229);
}
.signin-container {
  border: 1px solid grey;
  width: 40%;
  border-radius: 4px;
}
.signlogo {
  height: 50px;
  width: 50px;
  margin-right: 10px;
}
.signsection {
  margin: 0 auto;
  padding: 30px 20px;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.show-pass {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}
#showpass {
  cursor: pointer;
}
@media screen and (max-width: 670px) {
  .signin-container {
    width: 70%;
  }
}
@media screen and (max-width: 510px) {
  .sigin-title {
    font-size: 17px;
  }
  .signin-container {
    width: 90%;
  }
  .signsection {
    padding: 12px;
  }
}
