/* =====Fonts==== */
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;700&family=Poppins:ital,wght@0,400;0,700;1,500&family=Roboto:wght@400;500&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.main-row {
  position: relative;
  height: 100vh;
}
#sidebar {
  transition: all 0.5s linear;
  width: 20%;
  z-index: 99;
}
#view {
  width: 80%;
  background-color: #f6f6f6;
}
/* for input number remove arrow */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@media screen and (min-width: 600px) and (max-width: 1024px) {
  #view {
    width: 74%;
  }
  #sidebar {
    width: 26%;
  }
  .sb-container {
    width: 26% !important;
  }
}

@media screen and (max-width: 940px) {
  #containerFluid {
    height: 100vh;
  }

  .sb-container {
    width: 30% !important;
  }
  #sidebar {
    height: 100vh;
  }
}
@media screen and (max-width: 662px) {
  .main-row {
    overflow: auto;
  }
  .fs-6 {
    font-size: 16px !important;
  }
  #sidebar {
    width: 70% !important;
  }
  .smenu {
    padding: 6px 10px !important;
  }
  .menu-li {
    margin-left: 0 !important;
    border-radius: 0 !important;
    padding: 6px 10px !important;
  }
}
