.puja-confirm-parent-wrapper {
  width: 100%;
  overflow: hidden;
  padding: 30px;
  position: relative;
}

.inputError {
  border-bottom: 1px solid #dc3545 !important;
}

.puja-confirm-coordinate-ref-container div .inputError::placeholder,
.inputError::placeholder {
  color: #dc3545; /* Error color */
  opacity: 1; /* Ensure full color visibility */
}
.puja-confirm-puja-list-container {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  row-gap: 10vh;
  margin-top: 10px;
  border: 1px solid rgb(225, 225, 225);
  padding: 50px 20px;
}

.puja-confirm-image-and-title-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.puja-confirm-image-and-title-container > div:nth-child(2) {
  margin-top: 50px;
}

.puja-confirm-image-and-title-container > div:nth-child(2) > h1 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  letter-spacing: 1px;
  color: rgb(98, 40, 40);
  position: relative;
}

.puja-confirm-image-and-title-container > div:nth-child(2) > h1::after {
  content: "";
  width: 110%;
  height: 0.2px;
  /* background-color: rgb(229, 229, 229); */
  position: absolute;
  bottom: -5px;
  left: 0%;
}

.puja-confirm-puja-box {
  width: 100%;
  background-color: white;
  padding: 10px 20px;
  position: relative;
}
.cp-card-img {
  display: flex;
  justify-content: flex-start;
  padding: 5px;
  position: absolute;
  top: -5%;
  left: 39%;
  transform: translate(-0%, -50%);
}
.cp-card-img > img {
  width: 90px;
  height: 90px;
  border-radius: 50px;
  object-fit: cover;
  border: 6px solid rgb(243, 243, 243);
}
/* ------------------------------------- */
/* PUJA DATE PICKER CONTAINER */
.puja-confirm-date-picker-container {
  margin-top: 10px;
}
.puja-confirm-date-picker-container > h1 {
  font-size: 13px;
  color: brown;
  letter-spacing: 1px;
  font-weight: 600;
}
.puja-confirm-date-picker-container > div {
  width: 100%;
  justify-content: space-between;
  grid-gap: 10px;
}
/* .puja-confirm-date-picker-container > div div {
  display: flex;
  flex-direction: column;
} */
.puja-confirm-date-picker-container > div label {
  font-size: 13px;
  font-weight: 400;
  color: gray;
}
.puja-confirm-date-picker-container > div input {
  font-size: 14px;
  border: none;
  background-color: rgb(228, 255, 220);
  padding: 3px 6px;
  color: rgb(75, 75, 75);
  border-radius: 2px;
  font-weight: 500;
}
.puja-confirm-date-picker-container > div > div:nth-child(2) input {
  background-color: rgb(255, 232, 232);
}
/* -------------------------- */

/* DURATION SECTION --------------------------------- */
.puja-confirm-duration-container {
  margin-top: 10px;
}
.puja-confirm-duration-container > h1 {
  font-size: 13px;
  color: brown;
  letter-spacing: 1px;
  font-weight: 600;
}
.puja-confirm-duration-list-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.puja-confirm-duration-list-box {
  /* border:1px solid red; */
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 14px;
  font-weight: 450;
  border-radius: 4px;
  color: rgb(49, 49, 49);
}
.puja-confirm-duration-list-box > input {
  width: 15px;
  height: 15px;
}
/* --------------------------- */
/* COST SECTION----------------- */
.puja-confirm-cost-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(226, 226, 226);
}

.puja-confirm-cost-container > h1 {
  font-size: 13px;
  color: brown;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 0px;
}
.puja-confirm-cost-container > p {
  width: 90%;
  margin: 0px;
  padding: 2px 10px;
  border-radius: 2px;
  font-weight: 700;
  color: rgb(57, 57, 57);
  font-size: 14px;
  /* background-color: rgb(244, 244, 244); */
  text-align: right;
}

/* ------------------------------------------ */
/* DEDICATION SECTION */
.puja-confirm-dedication-container {
  margin-top: 10px;
}
.puja-confirm-dedication-container > h1 {
  font-size: 13px;
  color: brown;
  letter-spacing: 1px;
  font-weight: 600;
}
.puja-confirm-dedication-container > div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.puja-confirm-dedication-container > div p {
  margin: 0px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid rgb(180, 180, 180);
  padding: 3px 5px;
  color: gray;
  border-radius: 2px;
  cursor: pointer;
}
.puja-confirm-dedication-container > div p:hover {
  border: 1.3px solid red;
}
.puja-confirm-dedication-container > div > button {
  border: none;
  font-size: 12px;
  background-color: brown;
  padding: 3px 5px;
  color: white;
  border-radius: 3px;
  font-weight: 500;
}

/* PUJA CONFIRM PAYMENT ------------------------------- */
.puja-confirm-payment-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom:1px solid rgb(226, 226, 226); */
}
.puja-confirm-payment-container > h1 {
  font-size: 13px;
  color: brown;
  letter-spacing: 1px;
  font-weight: 600;
}
.puja-confirm-payment-container > div {
  display: flex;
  align-items: center;
  gap: 20px;
}
.puja-confirm-payment-container > div > div {
  display: flex;
  gap: 1px;
  align-items: center;
}
.puja-confirm-payment-container > div > div > input {
  width: 17px;
  height: 17px;
}
.puja-confirm-payment-container > div > div > label {
  font-size: 13px;
  font-weight: 600;
  color: rgb(73, 45, 39);
}
/* ---------- */

/* COORDINATOR REF */
.puja-confirm-coordinate-ref-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom:1px solid rgb(226, 226, 226); */
  /* padding:5px 5px; */
}
.puja-confirm-coordinate-ref-container > h1 {
  font-size: 14px;
  color: brown;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 0px;
}
.puja-confirm-coordinate-ref-container > div input {
  width: 100%;
  border: none;
  /* background-color: rgb(245, 255, 238); */
  /* border:1px solid rgb(225, 222, 222); */
  border-bottom: 1px solid rgb(135, 132, 132);
  color: rgb(49, 49, 49);
  font-size: 13px;
  font-weight: 600;
  padding: 4px;
}
.puja-confirm-coordinate-ref-container > div input::placeholder {
  color: rgb(208, 208, 208);
  font-weight: 400;
  font-size: 13px;
}
.puja-confirm-coordinate-ref-container > div input:focus {
  outline: none;
  border-bottom: 2px solid rgb(110, 46, 34);
  background-color: transparent;
  /* text-align: left; */
}

/* ---------------------------------- */

/* PUJA GIFT CARD AND PRAYER LATER */

.puja-confirm-prayer-card-and-gift-cart-container {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  padding: 20px 0px;
  margin-bottom: 30px;
}
.puja-confirm-prayer-card-and-gift-cart-container > div {
  display: flex;
  align-items: center;
}
.puja-confirm-prayer-card-and-gift-cart-container > div > input {
  width: 15px;
  height: 15px;
}
.puja-confirm-prayer-card-and-gift-cart-container > div > label {
  font-size: 12px;
  color: rgb(81, 81, 81);
}

/* ---------------------------------- */

.puja-confirm-button {
  border: none;
  width: 90%;
  background-color: rgb(121, 48, 48);
  color: white;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 5px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ADD MORE PUJA AND ADD DEDICATE */
.puja-confirm-addmore-and-add_dedicate-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.puja-confirm-addmore-and-add_dedicate-container > button {
  border: none;
  padding: 7px 8px;
  font-size: 11px;
  font-weight: 600;
  color: white;
  border-radius: 4px;
}
.puja-confirm-addmore-and-add_dedicate-container > button:nth-child(1) {
  background-color: brown;
}
.puja-confirm-addmore-and-add_dedicate-container > button:nth-child(2) {
  background-color: rgb(45, 145, 65);
}
/*  */

/* ACUALLY PAID SECTION-------------------------------------- */
.acutally-paid-maincontainer {
  margin-top: 10px;
  margin-bottom: 9vh;
}
.acutally-paid-maincontainer > h1 {
  font-size: 14px;
  color: brown;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 0px;
}
/* .acutally-paid-maincontainer > div {
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 10px;
} */
.acutally-paid-maincontainer > div > div {
  width: 100%;
}
.acutally-paid-maincontainer > div > div label {
  font-size: 12px;
  color: rgb(123, 123, 123);
  font-weight: 600;
}
/* .acutally-paid-maincontainer > div > div:nth-child(1) {
  width: 60%;
} */

/* .acutally-paid-maincontainer > div > div:nth-child(2) {
  width: 40%;
  display: flex;
  align-items: flex-end;
  gap: 5px;
  margin-top: 10px;
} */
.acutally-paid-maincontainer > div > div:nth-child(2) div:nth-child(1) {
  font-size: 17px;
  font-weight: 400;
  color: rgb(249, 96, 96);
  position: relative;
}
/* .acutally-paid-maincontainer > div > div:nth-child(2) div:nth-child(1)::after {
  content: ":";
  font-weight: 700;
  color: black;
  margin: 0px 5px;
} */

.acutally-paid-maincontainer
  > div
  > div:nth-child(2)
  div:nth-child(2)
  input:focus {
  outline: none;
  border-bottom: 2px solid brown;
}

.acutally-paid-maincontainer > div > div:nth-child(2) div > input {
  width: 100%;
  font-size: 18px;
  border: 1px solid rgb(196, 196, 196);
  border-radius: 2px;
}

.acutally-paid-maincontainer > div > div > select {
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  padding: 4px;
  border-radius: 3px;
  color: rgb(82, 81, 81);
  font-weight: 500;
  /* border:none; */
  border: 1px solid rgb(227, 227, 227);
  /* background-color: rgb(228, 240, 255); */
}
.acutally-paid-maincontainer > div > div > select:focus {
  outline: none;
}
.acutally-paid-maincontainer > div > div:nth-child(2) div:nth-child(2) input {
  border: none;
  border-bottom: 1px solid rgb(203, 203, 203);
  font-size: 18px;
  font-weight: 500;
  color: rgb(82, 82, 82);
  padding: 0px;
  text-align: left;
}
.acutally-paid-maincontainer
  > div
  > div:nth-child(2)
  div:nth-child(2)
  input::placeholder {
  color: rgb(170, 170, 170);
  font-weight: 400;
}

/* ------------------------------------------------------------- */

/* ?kfalsdkflksdlfksadlfklsdakflfsaldkflaksdflksdlkflsdakflkdflkasldfklsadkflksdflksdlfks */

/* DEDICATED_--------------CSSS_------------------------------------------------- */

.dedicated-list-main-wrapper {
  border: 1px solid rgb(223, 223, 223);
  /* height:70vh; */
  position: fixed;
  top: 10%;
  left: 40%;
  z-index: 99999;
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 3px 4px 2px rgb(234, 234, 234);
  padding: 10px;
  border-radius: 5px;
}
.dedicated-list-main-wrapper > h1 {
  text-align: center;
  font-size: 20px;
  color: rgb(89, 89, 89);
  border-bottom: 1px solid rgb(230, 229, 229);
  padding: 10px;
}

.dedicated-add-more-container {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  margin-top: 10px;
}
.dedicated-add-more-container > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
.dedicated-add-more-container > div > label {
  font-size: 14px;
  color: rgb(83, 52, 52);
  margin-bottom: 5px;
  font-weight: 600;
  text-indent: 5px;
}
.dedicated-add-more-container > div > input {
  background-color: transparent;
  border: 1px solid rgb(215, 215, 215);
  border-radius: 3px;
  padding: 3px;
  color: rgb(85, 85, 85);
  text-indent: 4px;
}
.dedicated-add-more-container > div > p {
  font-size: 12px;
  text-indent: 5px;
  color: red;
  margin: 0px;
}

.dedicated-add-more-container > div > input:focus {
  outline: none;
  border: 1px solid rgb(129, 66, 66);
}

.dedicated-add-more-container > div > button {
  border: none;
  font-size: 15px;
  background-color: rgb(77, 155, 47);
  padding: 4px 10px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  border-radius: 4px;
}
.dedicated-name-email-list-container {
  padding: 10px;
  height: 35vh;
  overflow-y: scroll;
  border: 1px solid rgb(223, 222, 222);
  margin-top: 10px;
  margin-bottom: 10vh;
}
.dedicated-name-email-list-container > div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(223, 223, 223);
  padding: 4px 0px;
}
.dedicated-name-email-list-container > div > input {
  width: 16px;
  height: 16px;
}
.dedicated-name-email-list-container > div > p {
  margin: 0px;
  width: 40%;
  padding: 0px;
  text-align: left;
  word-wrap: break-word;
  font-size: 13px;
  /* border:2px solid red; */
}
.dedicated-name-email-list-container > div > i {
  color: tomato;
  cursor: pointer;
}
.dedicated-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: red;
  transition: all 0.3 ease;
}
.dedicated-close-button:hover {
  color: rgb(255, 42, 42);
  transform: scale(1.2);
}
.dedication-form-add-list-to-puja-button-container {
  display: flex;
  justify-content: flex-end;
}
.dedication-form-add-list-to-puja-button-container > button {
  border: none;
  background-color: rgb(123, 56, 56);
  padding: 4px 10px;
  color: white;
  border-radius: 4px;
}
@media screen and (max-width: 786px) {
  .puja-confirm-puja-list-container {
    padding: 0;
    grid-gap: 0;
  }
}
@media screen and (max-width: 731px) {
  .puja-confirm-puja-list-container {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 50px;
  }
  .puja-confirm-puja-box {
    width: 70%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 542px) {
  .puja-confirm-puja-list-container {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 50px;
  }
  .puja-confirm-puja-box {
    width: 90%;
    padding: 0;
    margin: 0 auto;
  }
}
