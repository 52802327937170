.inputimg {
  cursor: pointer;
  padding: 5px 10px;
  background: rgba(179, 179, 179, 0.44);
  border: 1px solid rgba(58, 58, 58, 0.816);
  border-radius: 2px;
}
#previmg {
  height: 160px;
  width: 80%;
  border-radius: 2px;
  border: 1px dotted rgb(136, 61, 61);
}
.noImg {
  background-color: #ffb9b9;
}
#mainImg {
  background-position: center;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  color: rgb(136, 61, 61);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  position: relative;
}
.closebtn {
  position: absolute;
  top: 2px;
  font-size: 18px;
  cursor: pointer;

  right: 10px;
}
.name-bg {
  width: 100%;
  background: rgb(164, 91, 91);
  color: #fff;
  text-align: center;
}

.adpuja-i {
  background: linear-gradient(0deg, #e1e1e1, #e1e1e1),
    linear-gradient(0deg, #e7e7e7, #e7e7e7);
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid rgba(92, 92, 92, 0.697);
  transition: all 0.3s linear;
  border-radius: 3px;
}
.adpuja-i i {
  margin-right: 5px;
}
.adpuja-i:hover {
  background: #fff;
  border: 1px solid linear-gradient(0deg, #e1e1e1, #e1e1e1),
    linear-gradient(0deg, #e7e7e7, #e7e7e7);
}
.errorp {
  color: red;
}
