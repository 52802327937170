

.pagination-main-component{
    margin:0px auto;
    width:96%;
    display: flex;
    justify-content:flex-end;
    align-items: center;
    margin-bottom:15vh;
  
}

.pagination-main-component > div{
    display: flex;
    background-color: white;
    padding:10px 30px;
    gap:10px;
}
.pagination-main-component >div button{
    padding:5px 14px;
    font-size:14px;
    border:none;
    font-weight:600;
    background-color: rgb(118, 56, 56);
    border-radius:3px;
    color:rgb(255, 255, 255);
}
.pagination-main-component>div button:nth-child(1),
.pagination-main-component>div button:nth-child(5){
    background-color: transparent;
    border:1px solid gray;
    color:black;
}
.pagination-main-component>div button:nth-child(1):hover,
.pagination-main-component>div button:nth-child(5):hover{
    border:none;
    background-color: rgb(255, 103, 103);
    color:white;
}
.pagination-main-component>div button:nth-child(2):hover,
.pagination-main-component>div button:nth-child(4):hover{
    border:none;
    background-color: rgb(169, 91, 91);
    color:white;
}




.pagination-main-component > div > div{
    display: flex;
    gap:10px;
    align-items: center;
    justify-content: center;
}
.pagination-main-component> div > div li{
    list-style: none;
    font-size:14px;
    padding:5px 14px;
    font-weight:600;
    color:rgb(76, 76, 76);
    border-radius:3px;
    border:1px solid gray;
    cursor: pointer;

}
.pagination-main-component >div>div li:hover{
    background-color: rgb(251, 151, 151);
}