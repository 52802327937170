.pujaselect-page-main-container {
  background-color: rgb(246, 246, 246);
  padding: 10px 20px;
}

.select-all-puja-and-puja-selected-list-container {
  width: 100%;
  height: 6vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.select-all-puja-and-puja-selected-list-container > div:nth-child(1) {
  width: 80%;
  display: flex;
  gap: 10px;
  padding: 0px 10px;
}
.select-all-puja-and-puja-selected-list-container > div:nth-child(1) > h1 {
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  color: rgb(161, 61, 61);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}
.select-all-puja-and-puja-selected-list-container > div:nth-child(2) > button {
  border: none;
  background-color: rgb(255, 255, 255);
  color: rgb(60, 60, 60);
  font-size: 12px;
  padding: 7px 12px;
  border-radius: 4px;
  font-weight: 500;
  letter-spacing: 1px;
  border: 1px solid gray;
}

.puja-list-section {
  position: relative;
  margin-top: 5px;
  background-color: white;
  padding: 20px;
  min-height: 70vh;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 25px;
  margin-bottom: 5vh;
}

.puja-card-container {
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  gap: 10px;
  border: 1px solid rgb(224, 224, 224);
  border: 1.5px solid rgb(255, 207, 207);

  position: relative;
  cursor: pointer;
  transition: all 0.1s ease-in;
}
.puja-list-section > div:hover {
  border: 1.5px solid rgb(185, 80, 80);
}
.puja-list-section > div > img {
  width: 100%;
  height: 80%;
  object-fit: cover;
  /* border-radius:4px; */
}
.puja-list-section > div > h2 {
  font-size: 16px;
  color: rgb(113, 67, 50);
  text-transform: capitalize;
}
.puja-select-icon-container {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  background-color: rgb(255, 255, 255);
  background-color: rgb(64, 99, 255);
  color: white;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-puja-next-and-back-button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20vh;
}
.select-puja-next-and-back-button-container > button {
  border: none;
  padding: 4px 17px;
  background-color: rgb(115, 52, 52);
  color: white;
  border-radius: 4px;
}
