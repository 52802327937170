.das-container {
  width: 97%;
  margin: 25px auto;
}
.das-main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  align-items: center;
  justify-content: center;
}
.das-box {
  padding: 1.5rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.bg-blue {
  background: rgba(13, 202, 240, 0.75);
}
.bg-purple {
  background: rgba(111, 66, 193, 0.75);
}
.bg-teal {
  background-color: rgba(32, 201, 151, 0.75) !important;
}
.das-second {
  color: hsla(0, 0%, 100%, 0.9);
}
.imgicon {
  height: 50px;
  color: #fff;
  width: 50px;
  margin-right: 20px;
}
.das-out-p {
  padding: 0 10px;
}

/* ========das-down===== */
.das-down {
  display: grid;
  height: 256px;
  grid-template-columns: 60% 40%;
  margin-top: 31px;
  justify-content: center;
  gap:10px;
  padding:0px 15px;
}
.dasd-p {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 5px;
  columns:10px;
  gap:10px;
}
.dasdd-op {
  margin:5px 0px;
}
.dasd-main {
  padding: 1.5rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: #31df58;
}
.dasdicon {
  height: 50px;
  color: #fff;
  width: 50px;
  margin-right: 13px;
}
.dasd-second {
  color: hsla(0, 0%, 100%, 0.9);
}
.dasdd-op {
  flex-grow: 1;
}

/* right  */
.dasd-right {
  flex-grow: 1;
  /* border: 1px solid rgb(151, 151, 151); */
  padding:10px 20px;
  background: hsl(0, 0%, 90%);
  position: relative;
  min-height:350px;
  max-height: 420px;
  border-radius:4px;
}
.dasdr {
  height:96%;
  width: 97%;
  overflow: scroll;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-x: hidden;
  padding:0px 15px;
}
.dasdr-flex {
  display: flex;
  border-radius: 5px;
  text-transform: capitalize;
  margin: 10px 0;
  padding: 5px;
  align-items: center;
}
.dasdr-img {
  height: 60px;
  width: 60px;
  object-fit: contain;
}
.dasdr-flex-r {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.dasdr-number {
  margin-right: 10px;
  margin-bottom: 0;
}
.dasdr-flex{
  background-color: cfcfcf;
  color:rgb(89, 89, 89);

}

.dasdr-flex:nth-child(even) {
  background-color: rgb(255, 255, 255);
}

.dasdr-flex:nth-child(odd) {
  background-color: rgb(255, 253, 253);
}
/* =======for scroll bar=== */
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: rgb(226, 225, 225);
}
::-webkit-scrollbar-thumb {
  background: #805242;
}
@media screen and (max-width: 768px) {
  .das-out-p {
    padding: 0 7px;
  }
  .das-container {
    margin: 10px auto;
  }
  .dasd-main {
    padding: 1.3rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 120px;
    width: 100%;
  }
  .dasdicon {
    margin-right: 0;
    margin-bottom: 5px;
  }
  .das-box {
    padding: 10px;
    flex-direction: column;
  }
  .das-main {
    grid-gap: 0;
  }
  .imgicon {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .das-second,
  .dasd-second {
    text-align: center;
  }
  .das-down {
    flex-direction: column;
    margin-top: 15px;
  }
  .dasd-p {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 425px) {
  .imgicon {
    height: 40px;
    width: 40px;
  }
  .das-out-p {
    padding: 0 5px;
  }
  .das-main {
    margin-bottom: 10px;
  }
  .dasdr {
    height: auto;
    width: auto;
    position: initial;
    top: 0;
    left: 0;
    transform: none;
  }
  .das-down {
    grid-template-columns: 1fr;
    height: auto;
    margin-top: 0;
    grid-gap: 10px;
  }
  .dasd-main {
    flex-direction: row;
    padding: 1rem;
  }
  .dasdd-op {
    padding: 5px;
    display: flex;
    flex: 1;
  }
  .dasd-p {
    display: flex;
  }

  .dasdd-op {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
  }

  .dasd-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .dasd-second {
    height: 100%;
  }

  .dasdr-main > .dasdr-flex:first-child {
    padding-top: 0;
  }

  .dasdr-main > .dasdr-flex:last-child {
    padding-bottom: 0;
  }
  .das-container {
    margin: 20px auto;
  }
  .dasd-right {
    height: auto;
  }
}
