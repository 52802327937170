.btn-add-new {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: end;
}
.btn-add-new a {
  text-decoration: none;
  background: #653324;
  color: #fff;
  padding: 7px 10px;
  border-radius: 5px;
  float: right;
  font-size: 12px;
  font-weight: bold;
  margin: 0 0;
  margin-right: 20px;
  border: 1px solid #3a2424;
  transition: all 0.3s linear;
}
.btn-add-new a:hover {
  background: #fff;
  color: #3a2424;
  border: 1px solid #3a2424;
}
.cd-container {
  margin: 20px;
  padding: 10px;
  height: 70%;
  background: #fff;
}
.cd-plus {
  margin: 0 10px;
}
thead tr {
  border-bottom: 1px solid #6c757d;
}
.cd-list-title,
.cd-list {
  color: #343a40;
  font-family: Roboto;
  font-weight: 500;
  line-height: 23px;
  text-transform: capitalize;
  position: relative;
  width: 100%;
}

.cd-code,
.cd-name,
.cd-address,
.cd-country,
.cd-action,
.cd-email {
  padding: 10px 0;
  cursor: pointer;
}
.cd-list-title th,
.cd-list-title td {
  padding: 2px;
  font-size: 14px;
}

tr {
  border-bottom: 1px solid rgb(209, 206, 206);
}
/* table column width */

.col.cd-list-title .cd-code {
  width: 50px;
}

.col.cd-list-title .cd-name {
  width: 150px;
}

.col.cd-list-title .cd-address {
  width: 150px;
}

.col.cd-list-title .cd-country {
  width: 100px;
}

.col.cd-list-title .cd-email {
  width: 200px;
}

.col.cd-list-title .cd-action {
  width: 100px;
  text-align: center;
}
/* table column width */
.cd-list {
  color: #6c757d;
  border: none;
  text-transform: none;
}
.cd-code i,
.cd-name i,
.cd-address i,
.cd-country i,
.cd-action i,
.cd-email i {
  margin: auto;
}
.action-more {
  position: absolute;
  z-index: 99;
  padding: 0 !important;
  bottom: -45px;
  right: 0px;
  background: #c3c3c3;
  border-radius: 3px;
}
.cdaction-btn {
  transition: all 0.3s linear;
  color: rgb(152, 152, 152);
}
.cdaction-btn:hover {
  color: #793e2c;
}

.action-btn {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
.action-edit a,
.action-delete a,
.action-bars i,
.action-cart a {
  font-size: 14px;
  padding: 5px 7px;
  border-radius: 3px;
  margin: 3px;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 3s linear ease;
}
.action-edit a {
  color: green;
  background: #fff;
}
.action-edit a:hover {
  color: #fff;
  background: green;
}
.action-delete {
  height: 20px;
  width: 20px;
  position: relative;
  color: red;
  background: rgb(245, 126, 126);
  border-radius: 2px;
  padding: 5px;
  transition: all 0.4s linear;
}
.action-delete:hover {
  background: rgb(255, 255, 255);
}
.action-delete .fa-trash {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cdlist-md {
  display: none;
}
#ibtn {
  display: none;
}
@media screen and (max-width: 768px) {
  .cd-container {
    padding: 0;
    margin: 10px;
  }
  #ibtn {
    display: block;
    text-align: center;
  }
  #actionbtn {
    display: none;
  }
  .action-btn {
    display: flex;
  }
  .col.cd-list-title .cd-action {
    width: 50px;
    text-align: center;
  }
  .cd-address,
  .cd-email {
    display: none;
  }
  .cd-list-title,
  .cd-list {
    grid-template-columns: 30px repeat(3, 1fr) 70px;
  }
  .cdlist-md {
    display: table-row;
  }
  .md-name {
    color: #343a40;
    font-family: Roboto;
    font-weight: 500;
    line-height: 23px;
    text-transform: capitalize;
  }
  .md-country {
    display: none;
  }
  .md-email,
  .md-address {
    display: grid;
    grid-template-columns: 13% 2% 50%;
  }
  .mdname-i {
    color: #6c757d;
    font-size: 95%;
  }
  .btn-add-new a {
    margin-right: 5px;
  }
}

@media screen and (max-width: 528px) {
  .cd-country {
    display: none;
  }
  .cd-list-title,
  .cd-list {
    grid-template-columns: 30px repeat(2, 1fr) 70px;
  }
  .md-country,
  .md-email,
  .md-address {
    display: grid;
    grid-template-columns: 21% 4% 50%;
  }
}
.color {
  color: #793e2c;
}
