


.filter-coordinator-main-component{
    border:none;
    padding:4px;
}

.filter-drop-down-general-design > select{

    padding:2px 10px;
    border:none;
    border-left:1px solid rgb(138, 138, 138); 
    font-size:13px;
    font-weight:500;
    letter-spacing:1px;
    color:rgb(67, 67, 67); 


}
.filter-drop-down-general-design > select > option{
    color:red;
    user-select:all;
    overflow-y:hidden; 
    all: unset;

}

.filter-drop-down-general-design  {
    display: flex;
    align-items: center;
    justify-content: center;
}
.filter-drop-down-general-design > select:focus{
    outline:none;
}


.filter-year-and-month-container{
    display: flex;
    gap:10px;
    align-items: center;
    justify-content: center;
    
}