.submitbtn {
  background: rgba(121, 62, 44, 1);
  padding: 2px 20px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
  text-decoration: none;
  font-family: Heebo;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: -0.03em;
  outline: none;
  border: 1px solid rgba(121, 62, 44, 1);
  transition: all 0.3s ease-in-out;
}

.submitbtn:hover {
  border: 1px solid rgba(121, 62, 44, 1);
  background-color: #fff;
  color: rgba(121, 62, 44, 1);
}
