.v-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #cecece;
  padding: 5px 20px;
  font-family: Heebo;
  font-size: 16px;
  font-weight: 550px;
  line-height: 37px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: #f4f4f4;
  color: gray;
  position: relative;
  z-index: 2;
}
.btn-profile {
  border: none;
  outline: none;
  margin-right: 3px;
}
.profile-menu {
  position: absolute;
  top: -105px;
  right: 0;
  transition: top 0.3s ease;
  z-index: 1;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
}
#barBtn {
  display: none;
  padding: 0;
  outline: none;
}
#barBtn i {
  border: 1px solid rgba(0, 0, 0, 0.609);
  border-radius: 2px;
  background: rgba(162, 162, 162, 0.687);
}

.profile {
  border: 1px solid red;
  cursor: pointer;
}
.userimg {
  height: 40px;
}
.username,
.usertype {
  font-size: 13px;
  text-transform: capitalize;
}
.username {
  color: black;
}
.usertype {
  font-size: 12px;
}
#barBtn {
  display: none !;
}
@media screen and (max-width: 768px) {
  .userimg {
    height: 35px;
  }
}
@media screen and (max-width: 940px) {
  #barBtn {
    display: flex;
  }
  #view {
    width: 100%;
  }
  #sidebar {
    position: absolute;
    top: 0;
    left: -700px;
    width: 40%;
  }
}
.logoutBtn,
.resetpassword {
  border: none;
  outline: none;
  width: 100%;
  padding: 5px;
  background: transparent;
  transition: all 0.5s linear;
  text-decoration: none;
}
.logoutBtn:hover,
.resetpassword:hover {
  background-color: rgb(225, 225, 225);
}
.resetIcon {
  height: 20px;
}
