.puja-container {
  width: 90%;
  margin: 30px auto;
}
.puja-white {
  background: #ffffff;
  min-height: 90vh;
  overflow: hidden;
  position: relative;
}

.puja-add-button-container {
  display: flex;
  align-items: flex-end;
  padding: 0px;
  justify-content: space-between;
}
.puja-add-button-container > h1 {
  font-size: 12px;
  background-color: white;
  padding: 6px 15px;
  text-align: left;
  color: brown;
  text-transform: uppercase;
}

.puja-addnew {
  color: #fff;
  background-color: #3a2424;
  padding: 5px 10px;
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s linear;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}
.puja-addnew:hover {
  transform: scale(1.1);
  background-color: #890000;
}
.browncolor {
  color: #885748;
}
.puja-list-title {
  text-transform: uppercase;
}
.puja-image img {
  height: 70px;
  width: 80px;
  object-fit: cover;
}
.pi-delete {
  width: 30px;
  height: 30px;
  background: #fe3838;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.pi-delete i {
  cursor: pointer;
  transition: all 0.2s ease-in;
  color: white;
  font-size: 14px;
}
.pi-edit i {
  background: #49a7ff;
  transition: all 0.2s ease-in;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px;
  color: white;
}
.pi-delete:hover i {
  transform: scale(1.3);
}
.pi-edit i:hover {
  transform: scale(1.1);
  background: #3868f6;
}

/*  */
.puja-list {
  /* border:2px solid red; */
  border-bottom: 1px solid rgb(169, 169, 169);
  animation-name: pujaDeletedAnimate;
  animation-duration: 1s;
  animation-iteration-count: 1;
  background-color: white;
  margin-top: 50px;
}
.puja-list:nth-child(1) {
  margin-top: 30px !important;
}

.pujaDuration {
  display: flex;
  gap: 10px;
}
@keyframes pujaDeletedAnimate {
}
