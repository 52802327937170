.sb-container {
  /* width:20%; */
  background-color: #793e2c;
  height: 100%;
  position: fixed;
  width: 20%;
}

/* =========title=========== */
.logo-img {
  height: 70px;
  margin-right: 15px;
}
.side-title {
  background: #fff;
  padding: 30px 0;
}
.t-name {
  font-family: "Heebo", Helvetica;
  font-weight: 700;
  color: #2f2f2f;
  font-size: 18px;
  margin-top: 10px;
}

/* ===========List============ */
.side-down {
  padding: 20px 10px;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 600;
  color: #ffffff;
  font-size: 20px;
  margin: 10px 0;
  letter-spacing: 0.5px;
  line-height: 12px;
}
.smenu {
  padding: 5px 10px;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 600;
  color: #ffffff;
  margin: 10px 0;
  letter-spacing: 0.5px;
  line-height: 12px;
}
.menu-icon {
  font-size: 20px;
  margin-right: 25px;
}
.menu-ul {
  list-style-type: none;
  padding-left: 5px;
  margin-top: 25px;
}
.menu-li {
  text-transform: capitalize;
  font-family: Poppins;
  font-weight: 500;
  color: #fff;
  font-size: 15px;
  padding: 5px 20px;
}
.nav-item .active,
.nav-item a:hover {
  background-color: #f3e6e228 !important;
  color: #fff !important;
}

.collapse:not(.show) {
  display: none;
}

@media screen and (max-width: 740px) {
  .sb-container {
    width: 40% !important;
  }
}
@media screen and (max-width: 560px) {
  .sb-container {
    width: 80% !important;
  }
}
