.ol-container {
  background: #ffffffe1;
  padding: 10px;
  width: 97%;
  margin: 0 auto;
  margin-top: 10px;
  position: relative;
  height: 70vh;

}
.ol-head {
  font-family: Roboto;
  font-weight: 500;
  border-bottom: 1px solid #6c757d;
}
.ol-list-con {
  border-bottom: 1px solid rgb(209, 206, 206);
}
.ol-head,
.ol-list {
  display: grid;
  grid-template-columns: 15% 13% 14% 11% 11% 13% 13% 10%;
  padding: 5px;
  border-bottom: 1px solid rgb(237, 237, 237);
}
.ol-payment {
  border-radius: 5px;
  padding: 3px 10px;
  /* background: rgb(35, 134, 35); */
  color: white;
}
.ol-more {
  cursor: pointer;
}

.sm-more {
  background: #ebdbce;
  color: #0c0c0c;
  border-radius: 10px;
  width: 70%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}
.sm-more {
  transition: all 0.3s ease-in-out;
}

.sm-more.hidden {
  display: none;
}
.sm-more.visible {
  display: block;
}
.ol-more-title {
  padding: 8px;
  background-color: #793e2c;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #fff;
  font-family: Poppins;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.olmi {
  position: absolute;
  right: 10px;
  top: 5px;
}
.olmi i {
  color: #ebdbce;
  padding: 6px;
  border-radius: 5px;
  cursor: pointer;
  background: red;
  transition: all 0.5s linear;
}
.olmi i:hover {
  background: #ebdbce;
  color: red;
}
.ol-more-d {
  padding: 13px 20px;
}
.ol-more-d > div {
  margin: 8px 0;
}
.ol-id {
  display: grid;
  grid-template-columns: 15% 3% 32% 15% 3% 32%;
}
.ol-select {
  width: 80%;
  padding: 1px 5px;
}
.oltype {
  color: #793e2c;
  text-transform: capitalize;
}
.ol-ded {
  display: grid;
  grid-template-columns: 8% 46% 46%;
  margin: 10px auto !important;
}
.ol-ded-head {
  background: #793e2c;
}
.oltyped {
  color: #ebdbce;
  text-transform: capitalize;
}
@media screen and (max-width: 950px) {
  .ol-id {
    display: grid;
    grid-template-columns: 15% 3% 32% 15% 3% 32%;
  }
}
@media screen and (max-width: 713px) {
  .ol-head,
  .ol-list {
    grid-template-columns: 20% 27% 23% 20% 10%;
  }
  .ol-sm {
    display: none;
  }
  .sm-more {
    width: 100%;
  }
}
@media screen and (max-width: 568px) {
  .ol-id {
    grid-template-columns: 40% 5% 55%;
  }
  .ol-more-d > div {
    margin: 0;
  }
  .ol-ded {
    grid-template-columns: 30% 70%;
  }
  .ol-ded-head {
    padding: 5px 0 !important;
  }
  .ol-more-d {
    padding: 13px 12px;
  }

  .oltyped {
    padding-left: 10px;
  }
}
