.forma-box {
  padding: 20px;
  background: #fff;
  width: 60%;
  margin-top: 5vh;
  margin-bottom: 10vh;
}
.forma-title {
  font-family: Heebo;
  font-size: 18px !important;
  text-transform: uppercase;
  color: rgb(136, 61, 61);
  padding: 6px;
  background: rgba(229, 215, 210, 1);
  display: flex;
  justify-content: center;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 5vh;
  border-radius: 4px;
  margin-top: 3vh;
}
.forma-container {
  min-height: 90vh;
  width: 100%;
}
.fi-name {
  color: rgba(90, 70, 63, 1);
  text-transform: capitalize;
  font-family: Heebo;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -0.03em;
  text-align: left;
}
.fi-input {
  width: 100%;
  padding: 5px 10px;
  outline: none;
  min-height: 38px;
  outline: 0 !important;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}
.fi-input:active,
.fi-input:focus {
  border: 1px solid #5773ff;
  box-shadow: 0 0 0 1px #2684ff;
}
.fi-input::placeholder {
  color: #79797974;
}
.fi-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  grid-column-gap: 35px;
}
.f-bottom {
  font-family: Heebo;
  font-weight: 300;
  line-height: 31px;
  letter-spacing: -0.03em;
  text-align: right;
  color: rgba(164, 158, 157, 1);
}
.f-btn a {
  background: rgba(121, 62, 44, 1);
  padding: 5px 20px;
  border-radius: 2px;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
  text-decoration: none;
  font-family: Heebo;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.03em;
  text-align: left;
  transition: all 0.3s ease-in-out;
}
.f-btn a:hover {
  border: 1px solid rgba(121, 62, 44, 1);
  background-color: #fff;
  color: rgba(121, 62, 44, 1);
}
@media screen and (max-width: 652px) {
  .forma-container {
    height: auto;
  }
  .forma-box {
    width: 100%;
    padding: 15px;
    margin: 20px 0;
  }
  .forma-title {
    padding: 0;
    margin: 10px 0;
  }
  .fi-grid {
    display: block;
  }
  .f-btn a {
    padding: 3px 20px;
  }
}
