

.searchbarcomponent-main-container{
    width:25%;
    padding:6px 10px;
    display: flex;
    align-items: center;
    justify-content:space-between;
    border-radius:3px;
    background-color: white;
    transition:all 0.2 ease-in;

}
.searchbarcomponent-main-container > input{
    width:90%;
    border:none;
    background-color: transparent;
    font-size:14px;
    
}
.searchbarcomponent-main-container > input::placeholder{
    color:rgb(173, 173, 173);
}
.searchbarcomponent-main-container > input:focus{
    outline: none;
}
/* .searchbarcomponent-main-container:focus-within{
    border:1.6px solid rgb(226, 226, 226);
    
} */
.searchbarcomponent-main-container > i{
    color:rgb(196, 196, 196);
}