.sp-indi {
  background: #793e2c;
  margin: 20px auto;
  color: #fff;
  font-size: 17px;
  text-transform: capitalize;
  padding: 8px 15px;
  width: 97%;
  border-radius: 4px;
}
.singleOrder-action-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(205, 205, 205);
  padding: 6px;
  margin: 10px auto;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  width: 40%;
}
.singleOrder-action-box i {
  font-size: 20px;
}
