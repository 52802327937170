.showIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0%, -50%);
  cursor: pointer;
}
.showIcon i {
  color: rgb(95, 94, 94);
  transition: all 0.3s linear;
}
.showIcon i:hover {
  color: black;
}
